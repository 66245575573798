<bit-simple-dialog>
  <i
    bit-dialog-icon
    class="bwi bwi-business tw-text-5xl tw-text-primary-500"
    aria-hidden="true"
  ></i>
  <span bitDialogTitle class="font-bold">{{ "upgradeOrganization" | i18n }}</span>
  <span bitDialogContent>
    {{ data.dialogBodyText }}
  </span>
  <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
    <ng-container *ngIf="data.orgCanManageBilling">
      <button
        bitButton
        buttonType="primary"
        [routerLink]="['/organizations', data.orgId, 'billing', 'subscription']"
        [queryParams]="{ upgrade: true }"
        (click)="dialogRef.close()"
      >
        {{ "upgrade" | i18n }}
      </button>
      <button bitButton buttonType="secondary" (click)="dialogRef.close()">
        {{ "cancel" | i18n }}
      </button>
    </ng-container>

    <ng-container *ngIf="!data.orgCanManageBilling">
      <button bitButton buttonType="primary" (click)="dialogRef.close()">
        {{ "ok" | i18n }}
      </button>
    </ng-container>
  </div>
</bit-simple-dialog>
