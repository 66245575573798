<ng-container *ngIf="!hide">
  <ng-container [ngSwitch]="displayMode">
    <ng-container *ngSwitchCase="'noOrganizations'">
      <ul class="filter-options">
        <li class="filter-option active">
          <span class="filter-buttons">
            <button
              class="filter-button"
              [attr.aria-pressed]="activeFilter.myVaultOnly"
              appA11yTitle="{{ 'vault' | i18n }}: {{ 'myVault' | i18n }}"
            >
              <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
              {{ "myVault" | i18n }}
            </button>
          </span>
        </li>
        <li class="filter-option">
          <span class="filter-buttons">
            <a href="#" routerLink="/create-organization" class="filter-button">
              <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
              &nbsp;{{ "newOrganization" | i18n }}
            </a>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'personalOwnershipPolicy'">
      <div class="filter-heading">
        <button
          (click)="toggleCollapse()"
          title="{{ 'toggleCollapse' | i18n }}"
          class="toggle-button"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="organization-filters"
        >
          <i
            class="bwi bwi-fw"
            aria-hidden="true"
            [ngClass]="{
              'bwi-angle-right': isCollapsed,
              'bwi-angle-down': !isCollapsed
            }"
          ></i>
        </button>
        <button
          class="filter-button"
          (click)="clearFilter()"
          [ngClass]="{ active: !hasActiveFilter }"
          appA11yTitle="{{ 'vault' | i18n }}: {{ organizationGrouping.name | i18n }}"
        >
          &nbsp;{{ organizationGrouping.name | i18n }}
        </button>
      </div>
      <ul id="organization-filters" *ngIf="!isCollapsed" class="filter-options">
        <li
          class="filter-option"
          *ngFor="let organization of organizations"
          [ngClass]="{ active: organization.id === activeFilter.selectedOrganizationId }"
        >
          <span class="filter-buttons">
            <button
              class="filter-button"
              [ngClass]="{ 'disabled-organization': !organization.enabled }"
              (click)="applyOrganizationFilter(organization)"
              [attr.aria-pressed]="activeFilter.selectedOrganizationId === organization.id"
              appA11yTitle="{{ 'vault' | i18n }}: {{ organization.name }}"
            >
              <i class="bwi bwi-fw bwi-business" aria-hidden="true"></i>
              {{ organization.name }}
            </button>
            <span class="ml-auto">
              <i
                *ngIf="!organization.enabled"
                class="org-options bwi bwi-fw bwi-exclamation-triangle text-danger"
                attr.aria-label="{{ 'organizationIsDisabled' | i18n }}"
                appA11yTitle="{{ 'organizationIsDisabled' | i18n }}"
              ></i
              ><button [bitMenuTriggerFor]="orgMenu" class="org-options">
                <i class="bwi bwi-ellipsis-v" aria-hidden="true"></i>
              </button>
              <bit-menu class="filter-organization-options" #orgMenu>
                <app-organization-options [organization]="organization"></app-organization-options>
              </bit-menu>
            </span>
          </span>
        </li>
        <li class="filter-option">
          <span class="filter-buttons">
            <a href="#" routerLink="/create-organization" class="filter-button">
              <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
              &nbsp;{{ "newOrganization" | i18n }}
            </a>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'singleOrganizationAndPersonalOwnershipPolicies'">
      <div class="filter-heading">
        <button
          class="filter-button active"
          [attr.aria-pressed]="activeFilter.selectedOrganizationId === organizations[0].id"
          appA11yTitle="{{ 'vault' | i18n }}: {{ organizations[0].name }}"
        >
          <i class="bwi bwi-fw bwi-business" aria-hidden="true"></i>
          {{ organizations[0].name }}
        </button>
        <span class="tw-ml-auto">
          <button [bitMenuTriggerFor]="orgMenu" class="org-options">
            <i class="bwi bwi-ellipsis-v" aria-hidden="true"></i>
          </button>
          <bit-menu class="filter-organization-options" #orgMenu>
            <app-organization-options [organization]="organizations[0]"></app-organization-options>
          </bit-menu>
        </span>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="filter-heading">
        <button
          class="toggle-button"
          title="{{ 'toggleCollapse' | i18n }}"
          (click)="toggleCollapse()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="organization-filters"
        >
          <i
            class="bwi bwi-fw"
            aria-hidden="true"
            [ngClass]="{
              'bwi-angle-right': isCollapsed,
              'bwi-angle-down': !isCollapsed
            }"
          ></i>
        </button>
        <button
          class="filter-button"
          (click)="clearFilter()"
          [ngClass]="{ active: !hasActiveFilter }"
          appA11yTitle="{{ 'vault' | i18n }}: {{ organizationGrouping.name | i18n }}"
        >
          &nbsp;{{ organizationGrouping.name | i18n }}
        </button>
      </div>
      <ul id="organization-filters" *ngIf="!isCollapsed" class="filter-options">
        <li class="filter-option" [ngClass]="{ active: activeFilter.myVaultOnly }">
          <span class="filter-buttons">
            <button
              class="filter-button"
              (click)="applyMyVaultFilter()"
              appA11yTitle="{{ 'vault' | i18n }}: {{ 'myVault' | i18n }}"
              [attr.aria-pressed]="activeFilter.myVaultOnly"
            >
              <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
              {{ "myVault" | i18n }}
            </button>
          </span>
        </li>
        <li
          class="filter-option"
          *ngFor="let organization of organizations"
          [ngClass]="{ active: organization.id === activeFilter.selectedOrganizationId }"
        >
          <span class="filter-buttons">
            <button
              class="filter-button"
              [ngClass]="{ 'disabled-organization': !organization.enabled }"
              (click)="applyOrganizationFilter(organization)"
              appA11yTitle="{{ 'vault' | i18n }}: {{ organization.name }}"
            >
              <i class="bwi bwi-fw bwi-business" aria-hidden="true"></i>
              {{ organization.name }}
            </button>
            <span class="ml-auto">
              <i
                *ngIf="!organization.enabled"
                class="org-options bwi bwi-fw bwi-exclamation-triangle text-danger"
                appA11yTitle="{{ 'organizationIsDisabled' | i18n }}"
              ></i
              ><button [bitMenuTriggerFor]="orgMenu" class="org-options">
                <i class="bwi bwi-ellipsis-v" aria-hidden="true"></i>
              </button>
              <bit-menu class="filter-organization-options" #orgMenu>
                <app-organization-options [organization]="organization"></app-organization-options>
              </bit-menu>
            </span>
          </span>
        </li>
        <li class="filter-option" *ngIf="!(displayMode === 'singleOrganizationPolicy')">
          <span class="filter-buttons">
            <a href="#" routerLink="/create-organization" class="filter-button">
              <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
              &nbsp;{{ "newOrganization" | i18n }}
            </a>
          </span>
        </li>
      </ul>
    </ng-container>
  </ng-container>
  <hr />
</ng-container>
